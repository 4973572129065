var fredagsrock = fredagsrock || {};

fredagsrock.TivoliArticles = [];

fredagsrock.TivoliArticle = function ($articleItem) {
    this.IsOpen = false;
    content = $articleItem.find('.content');
    this.Offset = $(content).offset().top + $(content).prop('scrollHeight') + $(content).siblings('.content-control').height() + 100;
    this.index = $articleItem.data("index");
};

fredagsrock.TivoliArticle.prototype.SetOpenState = function (isOpen) {
    this.IsOpen = isOpen;
};

fredagsrock.TivoliArticle.prototype.Getheight = function () {
    return this.Offset;

};

fredagsrock.TivoliEvents = [];

fredagsrock.TivoliEvent = function ($eventItem) {
    this.IsOpen = false;
    content = $eventItem.find('.artist-text');
    this.Offset = $(content).offset().top + $(content).prop('scrollHeight') + 200;
    this.index = $eventItem.attr('id');
    this.index = $eventItem.data("index");

};

fredagsrock.TivoliEvent.prototype.Getheight = function () {
    return this.Offset;

};