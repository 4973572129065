fredagsrock = fredagsrock || {};

fredagsrock.parallax = (function () {
    
    function init() {
        var IE10 = navigator.userAgent.toString().toLowerCase().indexOf("trident/6")>-1;
        var $parallaxElm = $(".bg-parallax");

       if(!window.requestAnimationFrame || IE10)
       {
           $parallaxElm.css('position', 'absolute');
           return;
       }

        if(navigator.userAgent.match(/Trident\/7\./)) {
            $('body').on("mousewheel", function () {
                event.preventDefault();
                var wd = event.wheelDelta;
                var csp = window.pageYOffset;
                window.scrollTo(0, csp - wd);
            });
        }

        var $window = $(window);
        var windowHeight = $(window).height();
       
        var documentHeight = $(document).height() + 300;
        
        var $body = $('body');
        var ratio = ratio = 1800 / 2486;
        var height = $window.width() / ratio;
        var realHeight = height - windowHeight;

        scrollBackground($parallaxElm, documentHeight, windowHeight, realHeight);

        $window.on('resize', function() {
            windowHeight = $(window).height();
            height = $window.width() / ratio;
            realHeight = height - windowHeight;
            documentHeight = $(document).height();

            scrollBackground($parallaxElm, documentHeight, windowHeight, realHeight);
        });

        var timeout;
        window.addEventListener('scroll', function ( event ) {
            if (timeout) {
                window.cancelAnimationFrame(timeout);
            }
            
            timeout = window.requestAnimationFrame(function () {
                scrollBackground($parallaxElm, documentHeight, windowHeight, realHeight);
            });
        }, false);

    }

    function scrollBackground($parallaxElm, documentHeight, windowHeight, realHeight) {
        var scrollPercent = 100 * $(window).scrollTop() / (documentHeight - windowHeight);
        var yPos = (scrollPercent / 100) * (realHeight);
        $parallaxElm.css({"transform": "translate3d(0px, -" + parseInt(yPos) + "px, 0px)"});
    }

    $(document).ready(init);
})();
