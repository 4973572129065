fredagsrock = fredagsrock || {};

fredagsrock.newsletterSticky = (function () {
    var stickyContainer = $('.newsletter-sticky'),
        stickyButton = $('.newsletter-sticky__button'),
        stickyClose = $('.js-newsletter-sticky-close'),
        overlay = $('.newsletter-sticky-overlay'),
        scrollWrapper = $('.newsletter-sticky .scroll-wrapper'),
        scrollArea = $('.newsletter-sticky .scroll-area'),
        html = $('html'),
        stickyClassOpen = "newsletter-sticky--open",
        stickyClassActive = "newsletter-sticky-is-active",
        stickyClassOverlayActive = "newsletter-sticky-overlay--active",
        stickyClassButtonMinified = "newsletter-sticky__button--minified";

    var vars = {
        cookie: fredagsrock.Cookies
    };

    var newsletterStickyCookie = vars.cookie.get("newsletterSticky");

    function init() {
        var stickyLength = $('.js-newsletter-sticky').length;
        if (stickyLength === 0 || newsletterStickyCookie === "true") {
           return;
        }

        initStickyButton();
        setNewsletterCookie();
    }

    function initStickyButton() {
        stickyButton.show();
        scrollWrapper.css('max-height', scrollArea.outerHeight());


        stickyButton.on('click', function (e) {
            e.preventDefault();
            openOverlay();
        });

        $(window).one('scroll', function () {
            stickyButton.addClass(stickyClassButtonMinified);
        });
    }


    function openOverlay() {
        stickyContainer = $('.newsletter-sticky'),
        overlay = $('.newsletter-sticky-overlay'),
        stickyButton = $('.newsletter-sticky__button');
        html = $('html'),
        stickyClassOpen = "newsletter-sticky--open",
        stickyClassActive = "newsletter-sticky-is-active",
        stickyClassOverlayActive = "newsletter-sticky-overlay--active",
        stickyClose = $('.js-newsletter-sticky-close');

        stickyContainer.addClass(stickyClassOpen);
        overlay.addClass(stickyClassOverlayActive);
        html.addClass(stickyClassActive);

        overlay.on('click', function () {
            closeOverlay();
        });

        stickyClose.on('click', function (e) {
            e.preventDefault();
            closeOverlay();
        });
    }

    function closeOverlay() {
        stickyContainer = $('.newsletter-sticky'),
        stickyButton = $('.newsletter-sticky__button'),
        stickyClose = $('.js-newsletter-sticky-close'),
        overlay = $('.newsletter-sticky-overlay'),
        scrollWrapper = $('.newsletter-sticky .scroll-wrapper'),
        scrollArea = $('.newsletter-sticky .scroll-area'),
        html = $('html'),
        stickyClassOpen = "newsletter-sticky--open",
        stickyClassActive = "newsletter-sticky-is-active",
        stickyClassOverlayActive = "newsletter-sticky-overlay--active",
        stickyClassButtonMinified = "newsletter-sticky__button--minified";

        stickyButton.show();
        scrollWrapper.css('max-height', scrollArea.outerHeight());

        stickyContainer.removeClass(stickyClassOpen);
        html.removeClass(stickyClassActive);
        overlay.removeClass(stickyClassOverlayActive);
        stickyButton.addClass(stickyClassButtonMinified);

        stickyButton.on('click', function (e) {
            e.preventDefault();
            openOverlay();
        });
    }

    function setNewsletterCookie() {
        vars.cookie.set("newsletterSticky", "true", 24 * 365);
    }

    $(document).ready(init);

    return {
        openOverlay: openOverlay,
        closeOverlay: closeOverlay
    };
})();
