

var activeEvent = $(".event-holder .nextconcert");
var selectedEvent = $(".event-holder .selected");
var infoBox = $(".event-holder .inner.infobox");

function callFancyBox(my_href) {
    var j1 = document.getElementById("hiddenclicker");
    j1.href = my_href;
    $('#hiddenclicker').trigger('click');
}

function GetURLParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

var fredagsrock = fredagsrock || {};

fredagsrock = function ($) {

    return {
        Init: function () {

            
        window.addEventListener('CookieInformationConsentGiven', function (event) {
            if (CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
                fredagsrock.Cookies.set('sat_track', true, 24);
            }
            else {
                fredagsrock.Cookies.set('sat_track', false, 24);
            }
            if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
                fredagsrock.Cookies.set('sat_track_marketing', true, 24);
            }
            else {
                fredagsrock.Cookies.set('sat_track_marketing', false, 24);
            }
        }, false);

            //Zebra stribes on Concert list
            var i = 0;
            $('.box-container').each(function () {
                if ($(this).hasClass('monthBox')) {
                    i = 0;
                }
                var addClass = i % 2 == 0 ? 'odd' : 'even';

                if ($(this).hasClass('eventBox')) {
                    $(this).addClass(addClass);
                    i++;
                }
            });

            $("input[placeholder]").placeholder();

            //Newsletter fancybox
            $(".main-menu .newsletter a").fancybox({
                padding: 0,
                prevEffect: 'none',
                nextEffect: 'none',
                'width': 306,
                helpers: {
                    title: { type: 'outside' },
                    thumbs: {
                        width: 50,
                        height: 50
                    }
                }
            });

            //Newsletter fancybox
            $(".countdownBox a").fancybox({
                padding: 0,
                prevEffect: 'none',
                nextEffect: 'none',
                'width': 306,
                helpers: {
                    title: { type: 'outside' },
                    thumbs: {
                        width: 50,
                        height: 50
                    }
                }
            });

            $("#hiddenclicker").fancybox({
                padding: 0,
                prevEffect: 'none',
                nextEffect: 'none',
                'width': 306,
                helpers: {
                    title: { type: 'outside' },
                    thumbs: {
                        width: 50,
                        height: 50
                    }
                }
            });

            //Show all concerts if all events is expired
            if ($('.eventBox:not(.inactive)').length == 0) {
                $('.events-holder').addClass('eventsOver');
            }
            else {
                //Enable Show previous Concert button
                if ($('.monthBox.current').siblings('.eventBox.inactive').length != 0) {
                    $('.monthBox.current').addClass('enablePrev');
                }
            }

            //$('.social-ad a').hoverIntent(function() {
            //    $('.social-ad-inactive').hide();
            //    $('.social-ad-active').show();
            //}, function () {
            //    $('.social-ad-active').hide();
            //    $('.social-ad-inactive').show();
            //});

            //Toogle Concerts on Click
            $('.eventBox .inner .image .event-control a').click(function (event) {

                event.preventDefault();

                thisSel = $(this).parents('.eventBox');
                fredagsrock.eventTextToogle.toogle(thisSel);
            });



            $('.eventBox .closeLink').click(function (event) {
                event.preventDefault();

                thisSel = $(this).parents('.eventBox');
                fredagsrock.eventTextToogle.toogle(thisSel);
            });




            //Toogle Article on Click
            $('.content-control').click(function (event) {
                event.preventDefault();

                $article = $(this).parent().parent();
                fredagsrock.toogleArticle.Init($article);
            });

            //Toogle Menu
            $('.menu-controller').click(function (event) {
                event.preventDefault();

                if ($('.menu-content').hasClass('open')) {
                    $('.menu-content').removeClass('open');
                    $('.menu-burger').removeClass('menu-is-active');
                } else {
                    $('.menu-content').addClass('open');
                    $('.menu-burger').addClass('menu-is-active');
                }
            });



            //Spotify
            $('.spotify').click(function (event) {
                $spotifyBox = $(this).parents('.nav-extra ').siblings('.spotify.extraBox').find('.spotifyData');
                var spotifyIframe = $spotifyBox.attr('data-spotify');

                if ($spotifyBox.children().length == 0) {
                    $('<iframe src="' + spotifyIframe + '" width="240" height="80" frameborder="0" allowtransparency="true"></iframe>').appendTo($spotifyBox);
                }
            });

            if (GetURLParameter('newsletter') == 'true') {
                window.setTimeout("callFancyBox('#fancy-newsletter-thanks');", 1000);
            }

            if (GetURLParameter('newsletter') == 'open') {
                window.setTimeout("callFancyBox('#fancy-newsletter');", 1000);
            }

            $("a.fancy").fancybox({
                'overlayOpacity': 0.7,
                'overlayColor': '#000',
                'titlePosition': 'over',
                'width': 680,
                'height': 495,
                'onComplete': function () {
                    $("#fancybox-wrap").hover(function () {
                        $("#fancybox-title").show();
                    }, function () {
                        $("#fancybox-title").hide();
                    });
                }
            });

            //Youtube-Player
            var youtubeEnabled = false;
            $('.youtube-player').click(function () {
                if (!youtubeEnabled) {
                    $(this).find('.player').show();
                    var video = $(this).attr('data-attr-video');
                    $(this).find('.player').append('<iframe width="600" height="360" src="https://www.youtube.com/embed/' + video + '?autoplay=1&controls=0" frameborder="0" allowfullscreen></iframe>');
                    $(this).find('img').hide();
                    youtubeEnabled = true;
                }
            });

            //Click on dimmer
            $('#dim').click(function () {
                $('.dim-active').each(function (index) {
                    //Close article .next-event
                    if ($(this).hasClass('next-event') || $(this).hasClass('next-content') || $(this).hasClass('regular-content')) {
                        fredagsrock.toogleArticle.Init($(this));
                    }
                    if ($(this).hasClass('eventBox')) {
                        if ($(this).hasClass('active')) {
                            fredagsrock.eventTextToogle.toogle($(this));
                            $('.nav-extra .selected').removeClass('selected');

                            $(this).find('.event-control').fadeOut(300);

                            $(this).find('.buy-button').animate({
                                marginTop: '-55px'
                            }, 700);
                            $(this).find('.button-holder').animate({
                                marginTop: '-55px'
                            }, 700, function () {
                                $(this).find('.artist-intro.active').removeClass('active');
                            });

                            $(this).find('.extraBox:visible').slideUp('fast');
                            $('.next-event .nav-extra .selected').removeClass('selected');
                            $(this).children('.content-container').children('.buttons').children('.button-holder').children('.nav-extra').animate({
                                minHeight: "0"
                            }, 700);
                        }
                        else {
                            $('#dim').fadeOut(300);
                            $(this).removeClass('dim-active');
                        }
                    }

                });
            });


            //Toogle previous concerts 
            $('.showPreviousConcerts a').click(function (event) {
                event.preventDefault();
                if ($('.events-holder').hasClass('showAll')) {
                    $('.events-holder').removeClass('showAll');
                }
                else {
                    $('.events-holder').addClass('showAll');
                }
            });

            //Scroll to top
            $('.to-top a').click(function (event) {
                event.preventDefault();
                $("html, body").animate({ scrollTop: 0 });

            });

            if($('.sister-site img').length > 0) {
                $('.sister-site').addClass('sister-site--logo');
            }

            InitCountDown();

        }
    };
}(jQuery);

fredagsrock.adjustFooter = function (offset) {
    //var holderOffset = $('#wrapper').offset().top + $('#wrapper').height();
    var holderOffset = $('.site').offset().top + $('.site').height();
    var newbottom = offset - holderOffset;
    if (newbottom > 0) {
       // $('#wrapper').animate({
        $('.site').animate({
            paddingBottom: newbottom
        }, 700);
    } else {
       // $('#wrapper').animate({
        $('.site').animate({
            paddingBottom: 0
        }, 700);
    }
}

fredagsrock.toogleArticle = function ($) {
    return {
        Init: function ($article) {

            //Adjust footer space
            content = $article.find('.content');
            var Offset = $(content).offset().top + $(content).prop('scrollHeight') + $(content).siblings('.content-control').height() + 100;
            fredagsrock.adjustFooter(Offset);


            if (!$article.hasClass('active')) {
                if (!$('body').hasClass('mobile')) {
                    $article.addClass('dim-active');
                }

                $article.addClass('active');

                if (!$('body').hasClass('mobile')) {
                    $('#dim').fadeIn(300);
                }

                //GA
                var itemPlacement = "";
                if ($article.hasClass('regular-content')) {
                    itemPlacement = 'article';
                }
                else {
                    itemPlacement = 'topbanner'
                }
                var itemName = $article.attr('data-item-name');
                dataLayer.push({
                    'event': 'item-open',
                    'itemName': itemName,
                    'itemPlacement': itemPlacement
                });
            }
            else {
                $article.removeClass('active');

                $('#dim').fadeOut(300, function () {
                    $article.removeClass('dim-active');
                });

                $article.children('.content-container').children('.buttons').children('.button-holder').children('.extraBox:visible').slideUp('fast');
                $('.next-event .nav-extra .selected').removeClass('selected');
                $article.children('.content-container').children('.buttons').children('.button-holder').children('.nav-extra').animate({
                    minHeight: "0"
                }, 700);

                $('.site').animate({
                // $('#wrapper').animate({
                    paddingBottom: 0
                }, 700);

                //GA
                var itemPlacement = "";
                if ($article.hasClass('regular-content')) {
                    itemPlacement = 'article';
                }
                else {
                    itemPlacement = 'topbanner'
                }
                var itemName = $article.attr('data-item-name');
                dataLayer.push({
                    'event': 'item-close',
                    'itemName': itemName,
                    'itemPlacement': itemPlacement
                });

            }
        }
    };
}(jQuery);

fredagsrock.eventTextToogle = function ($) {
    return {
        toogle: function ($event) {
            var extra = $event.find('.artist-extra');

            if (!$(extra).children('.artist-text').is(':visible')) {

                $event.find('.artist-ad').hide();

                $event.addClass('dim-active');
                $(thisSel).addClass('active');
                $event.find('.event-control a').addClass('active');

                $(extra).children('.artist-text').slideDown('slow', function () {
                    $('#dim').fadeIn(300);

                });

                content = $event.find('.artist-text');
                var Offset = $(content).offset().top + $(content).prop('scrollHeight') + 300;

                fredagsrock.adjustFooter(Offset);


                //GA
                var itemName = $event.attr('data-item-name');
                dataLayer.push({
                    'event': 'item-open',
                    'itemName': itemName,
                    'itemPlacement': 'event'
                });

            }
            else {
                $event.find('.event-control a').removeClass('active');
                $event.removeClass('active');
                $(extra).children('.artist-text').slideUp('slow', function () {
                    $('#dim').fadeOut(300, function () {
                        $event.find('.artist-ad').slideDown();
                        $event.removeClass('dim-active');
                    });
                });
                //$('#wrapper').animate({
                $('.site').animate({
                    paddingBottom: 0
                }, 700);

                var itemName = $event.attr('data-item-name');
                dataLayer.push({
                    'event': 'item-close',
                    'itemName': itemName,
                    'itemPlacement': 'event'
                });
            }
        }
    };
}(jQuery);

fredagsrock.listToogle = function ($) {
    return {
        Init: function () {
            $('.showPanels .btn').click(function (event) {
                event.preventDefault();

                if ($('.event-holder').hasClass('showPanels')) {
                    $('.event-holder').addClass('showList');
                    $('.event-holder').removeClass('showPanels');
                    $('.showPanels .lists').hide();
                    $('.showPanels .panels').show();
                    $('.promote-holder').show();

                    dataLayer.push({
                        'event': 'change-display-mode',
                        'displayMode': 'Liste'
                    });
                }
                else {
                    $('.event-holder').removeClass('showList');
                    $('.event-holder').addClass('showPanels');
                    $('.showPanels .lists').show();
                    $('.showPanels .panels').hide();
                    $('.promote-holder').hide();

                    dataLayer.push({
                        'event': 'change-display-mode',
                        'displayMode': 'Panels'
                    });
                }
            });
        }
    };
}(jQuery);

fredagsrock.enableCountdowns = function ($) {
    return {
        Init: function () {
            $("[data-countdown]").each(function () {

                var $this = $(this), finalDate = $(this).data('countdown');
                $this.countdown(finalDate, function (event) {


                    if (event.strftime('%S') != $(this).children('.percent').text()) {
                        var percent = event.strftime('%S') / 59 * 100;
                        var deg = 360 / 100 * percent;

                        var percentMinute = event.strftime('%M') / 59 * 100;
                        var degMinute = 360 / 100 * percentMinute;

                        var percentHour = event.strftime('%H') / 23 * 100;
                        var degHour = 360 / 100 * percentHour;
                        var dayLabel = $(this).data('days-label');
                        if (event.strftime('%-D') == '1') {
                            dayLabel = $(this).data('day-label');
                        }

                        $this.html(
                              //Day
                             ' <div class="countdown-artist__day"><p>' + event.strftime('%-D') + ' ' + dayLabel + '</p>' +
                             //Hour
                             '</div><div class="timer"><div class="percent">' + event.strftime('%-H') + '</div><div class="slice-bg"></div><div class="slice ' + (percentHour > 50 ? 'gt50"' : '"') + '><div class="pie" style="' +
                                '-moz-transform:rotate(' + degHour + 'deg);' +
                                '-webkit-transform:rotate(' + degHour + 'deg);' +
                                '-o-transform:rotate(' + degHour + 'deg);' +
                                'transform:rotate(' + degHour + 'deg);' +
                                '"></div>' + (percentHour > 50 ? '<div class="pie fill" style="' +
                                    '-moz-transform:rotate(' + degHour + 'deg);' +
                                    '-webkit-transform:rotate(' + degHour + 'deg);' +
                                    '-o-transform:rotate(' + degHour + 'deg);' +
                                    'transform:rotate(' + degHour + 'deg);' +
                                    '"></div>' : '') + '</div></div>' +

                            //Minutes
                             '<div class="timer"><div class="percent">' + event.strftime('%-M') + '</div><div class="slice-bg"></div><div class="slice ' + (percentMinute > 50 ? 'gt50"' : '"') + '><div class="pie" style="' +
                                '-moz-transform:rotate(' + degMinute + 'deg);' +
                                '-webkit-transform:rotate(' + degMinute + 'deg);' +
                                '-o-transform:rotate(' + degMinute + 'deg);' +
                                'transform:rotate(' + degMinute + 'deg);' +
                                '"></div>' + (percentMinute > 50 ? '<div class="pie fill" style="' +
                                    '-moz-transform:rotate(' + degMinute + 'deg);' +
                                    '-webkit-transform:rotate(' + degMinute + 'deg);' +
                                    '-o-transform:rotate(' + degMinute + 'deg);' +
                                    'transform:rotate(' + degMinute + 'deg);' +
                                    '"></div>' : '') + '</div></div>' +

                            //Seconds
                            '<div class="timer"><div class="percent">' + event.strftime('%-S') + '</div><div class="slice-bg"></div><div class="slice ' + (percent > 50 ? 'gt50"' : '"') + '><div class="pie" style="' +
                                '-moz-transform:rotate(' + deg + 'deg);' +
                                '-webkit-transform:rotate(' + deg + 'deg);' +
                                '-o-transform:rotate(' + deg + 'deg);' +
                                'transform:rotate(' + deg + 'deg);' +
                                '"></div>' + (percent > 50 ? '<div class="pie fill" style="' +
                                    '-moz-transform:rotate(' + deg + 'deg);' +
                                    '-webkit-transform:rotate(' + deg + 'deg);' +
                                    '-o-transform:rotate(' + deg + 'deg);' +
                                    'transform:rotate(' + deg + 'deg);' +
                                    '"></div>' : '') + '</div></div>'
                );
                    }
                });


            });
        }
    };
}(jQuery);

fredagsrock.extraButtonFunctions = function ($) {
    return {
        Init: function () {
            $('.nav-extra a').click(function (event) {
                if (!$(this).hasClass('instagram')) {
                    event.preventDefault();
                }
                var currrentEvent = $(this).parents().parent().parent();

                if ($(this).hasClass('selected')) {
                    $(currrentEvent).children('.extraBox:visible').slideUp('fast');
                    $(this).removeClass('selected');

                    if (!$(this).parents('.eventBox').hasClass('active')) {
                        $('#wrapper').animate({
                            paddingBottom: 0
                        }, 700);
                    }
                }
                else {
                    var newClass = $(this).attr('class').split(' ')[0];

                    $('.nav-extra a.selected').removeClass('selected');
                    $(this).addClass('selected');

                    if (!$(currrentEvent).children('.extraBox').is(":visible")) {
                        $(currrentEvent).children('.extraBox.' + newClass).slideDown('fast');

                        if (!$(this).parents('.eventBox').hasClass('active') && $(this).parents('.eventBox').length > 0) {
                            content = $(this).parents('.eventBox').find('.extraBox');
                            var Offset = $(content).offset().top + 300;

                            if ($(this).hasClass('calendar')) {
                                Offset = $(content).offset().top + 1000;
                            }

                            fredagsrock.adjustFooter(Offset);
                        }


                    }
                    else {
                        $(currrentEvent).children('.extraBox:visible').slideUp('fast', function () {
                            $(currrentEvent).children('.extraBox.' + newClass).slideDown('fast');
                        });
                    }
                }
            });
        }
    };
}(jQuery);

fredagsrock.enableArticleHover = function ($) {
    return {
        Init: function () {
            if (!$('body').hasClass('mobile')) {
                $('.next-event').hoverIntent(function () {
                    $(this).addClass('selected');
                    $(this).children('.content-container').children('.buttons').children('.button-holder').children('.nav-extra').animate({
                        minHeight: "50px"
                    }, 700);
                }, function () {
                    if (!$(this).hasClass('active')) {
                        $(this).children('.content-container').children('.buttons').children('.button-holder').children('.extraBox:visible').slideUp('fast');
                        $('.next-event .nav-extra .selected').removeClass('selected');
                        $(this).children('.content-container').children('.buttons').children('.button-holder').children('.nav-extra').animate({
                            minHeight: "0"
                        }, 700);
                        $(this).removeClass('selected');
                    }
                });
            }
        }
    };
}(jQuery);

fredagsrock.eventText = function ($) {
    return {
        open: function ($event) {
            if ($('.event-holder').hasClass('showPanels') && $('body').hasClass('desktop')) {
                $event.addClass('selected');
                $event.find('.artist-intro').addClass('active');
                $event.find('.button-holder').animate({
                    marginTop: "0"
                }, 400);
                $event.find('.buy-button').animate({
                    marginTop: "0"
                }, 400);

                $event.find('.event-control').fadeIn(300);
            }
        },

        close: function ($event) {
            if ($('.event-holder').hasClass('showPanels')) {

                if (!$event.find('.artist-extra').height() > 0) {
                    $event.removeClass('selected');
                    $event.find('.extraBox:visible').slideUp('fast');
                    $('.nav-extra .selected').removeClass('selected');

                    $event.find('.event-control').fadeOut(300);

                    $event.find('.buy-button').animate({
                        marginTop: '-55px'
                    }, 400);
                    $event.find('.button-holder').animate({
                        marginTop: '-55px'
                    }, 400, function () {
                        $event.find('.artist-intro.active').removeClass('active');
                    });
                }
            }

        }
    };
}(jQuery);

fredagsrock.eventHover = function ($) {
    return {
        open: function ($event) {
            if ($('.event-holder').hasClass('showPanels') && $('body').hasClass('desktop')) {
                $event.addClass('selected');
                $event.find('.artist-intro').addClass('active');
                if (!$event.hasClass('inactive')) {
                    $event.find('.button-holder').animate({
                        marginTop: "0"
                    }, 500);
                    $event.find('.buy-button').animate({
                        marginTop: "0"
                    }, 500);
                }

                $event.find('.event-control').fadeIn(300);
            }
        },

        close: function ($event, $inactive) {
            if ($('.event-holder').hasClass('showPanels')) {

                if (!$event.find('.artist-extra').height() > 0) {
                    $event.removeClass('selected');
                    $event.find('.extraBox:visible').slideUp('fast');
                    $('.nav-extra .selected').removeClass('selected');

                    $event.find('.event-control').fadeOut(300);

                    if (!$event.hasClass('inactive')) {
                        $event.find('.buy-button').animate({
                            marginTop: '-55px'
                        }, 500);
                        $event.find('.button-holder').animate({
                            marginTop: '-55px'
                        }, 500, function () {
                            $event.find('.artist-intro.active').removeClass('active');
                        });
                    }

                    $('#wrapper').animate({
                        paddingBottom: 0
                    }, 700);
                }
            }

        }
    };
}(jQuery);

fredagsrock.enableHover = function ($) {
    return {
        Init: function () {
            var artIntro = $(this).find('.artist-intro');
            var inactive = false;


            $('.showPanels .eventBox').hoverIntent(function () {
                fredagsrock.eventHover.open($(this));

            }, function () {
                fredagsrock.eventHover.close($(this));
            });
        }
    };
}(jQuery);

fredagsrock.Cookies = (function () {
    var set = function (cname, cvalue, exhours) {
        var d = new Date();
        d.setTime(d.getTime() + (exhours * 3600000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + ((exhours === 0) ? "" : (expires + "; path=/"));
    };
    var get = function (cname) {
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1);
            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }
        return "";
    };

    return {
        set: set,
        get: get,
    };
})();

/********************************************/
/***** COOKIE DISCLAIMER ********************/
/********************************************/
fredagsrock.cookiedisclaimer = function ($) {
    return {
        Init: function () {

            var cookieActivated = false;

            /* First time visiting site */
            if (jQuery.cookie('cookie-disclaimer') != 'true') {
                fredagsrock.cookiedisclaimer.cookieshow();
                //var t = setTimeout(function () { fredagsrock.cookiedisclaimer.cookiehide(); }, 4000)
                jQuery.cookie('cookie-disclaimer', 'true', { expires: 14 });

                $(window).scroll(function () {
                    fredagsrock.cookiedisclaimer.cookiehide();
                    cookieActivated = true;
                });

                $(document).click(function (e) {
                    fredagsrock.cookiedisclaimer.cookiehide();
                    cookieActivated = true;
                });
            }

            jQuery(".cookie-container").mouseenter(function () {
                fredagsrock.cookiedisclaimer.cookieshow();
            });

            jQuery(".cookie-container").mouseleave(function () {
                fredagsrock.cookiedisclaimer.cookiehide();
            });

            $(".cookie-container").bind("click touch", function () {
                var attLeft = $(this).css("left");
                if (attLeft == "-2px") {
                    fredagsrock.cookiedisclaimer.cookiehide();
                }
                else {
                    fredagsrock.cookiedisclaimer.cookieshow();
                }
            });
        },
        cookieshow: function () {
            jQuery('.cookie-container').stop().animate({
                left: '-2'
            }, 500, function () {
                jQuery('.cookie-main').show(200);
            });
        },
        cookiehide: function () {
            jQuery('.cookie-main').hide(200);
            jQuery('.cookie-container').stop().animate({
                left: '-195'
            }, 500, function () {
                jQuery('.cookie-main').hide();
            });

        }
    };

}(jQuery);

/******************/
/* DOCUMENT READY */
/******************/
jQuery(document).ready(function () {
    fredagsrock.Init();
    fredagsrock.extraButtonFunctions.Init();
    fredagsrock.listToogle.Init();
    fredagsrock.cookiedisclaimer.Init();
    fredagsrock.enableArticleHover.Init();
    fredagsrock.enableCountdowns.Init();

    if ($('html').hasClass('ie8')) {
        $('body').addClass('desktop');
        fredagsrock.enableHover.Init();
    }

    FastClick.attach(document.body);
});

function InitCountDown() {
    $('.count-down').each(function () {
        var countDownDate = $(this).data("count-down-date");
        $('.count-down__days').countdown(countDownDate, function (event) {
            $(this).html(event.strftime('%-D'));
        });
        $('.count-down__hours').countdown(countDownDate, function (event) {
            $(this).html(event.strftime('%H'));
        });
        $('.count-down__minutes').countdown(countDownDate, function (event) {
            $(this).html(event.strftime('%M'));
        });
        $('.count-down__seconds').countdown(countDownDate, function (event) {
            $(this).html(event.strftime('%S'));
        });
    });
}

function NewsletterStickyOpen() {
    fredagsrock.newsletterSticky.openOverlay();
}

/***************/
/* WINDOW.LOAD */
/***************/
jQuery(window).load(function () {




    Modernizr.load([
    //first test need for matchMedia polyfill
            {
                test: window.matchMedia,
                nope: "/js/nimb/web/matchmedia.js"
            },
    //and then load enquire
             {
                 load: "/js/nimb/web/enquire.js",
                 complete: function () {
                     enquire.register("screen and (max-width:611px)", {
                         // 1 column
                         match: function () {
                             $('body').removeClass('desktop');
                             $('body').addClass('mobile');
                             $('.event-control').show();
                             $('.artist-extra').show();
                             $('#dim').hide()

                             $(".eventBox.active").each(function (index, elem) {
                                 fredagsrock.eventTextToogle.toogle($(this));
                             });

                             $(".regular-content.active").each(function (index, elem) {
                                 fredagsrock.toogleArticle.Init($(this));
                             });
                             if (($('.next-content .content').prop('scrollHeight')) <= $('.next-content .content').height() + 60) {
                                 $('.next-content .content-control').hide();
                                 //                             $('.next-content .fade-overlay').hide();
                             }

                             $('.regular-content').each(function () {
                                 if (($(this).find('.content').prop('scrollHeight')) <= $(this).find('.content').height() + 60) {
                                     $(this).find('.content-control').hide();

                                     //                                 $(this).find('.fade-overlay').hide();
                                 }
                             });

                         }

                         // 2 Column
                     }).register("screen and (min-width : 612px) and (max-width : 917px)", {
                         match: function () {
                             $('body').addClass('desktop');
                             $('body').removeClass('mobile');
                             fredagsrock.enableHover.Init();
                             $('#wrapper').css('padding-bottom', 0);

                             $(".eventBox.active").each(function (index, elem) {
                                 fredagsrock.eventTextToogle.toogle($(this));
                             });

                             $(".regular-content.active").each(function (index, elem) {
                                 fredagsrock.toogleArticle.Init($(this));
                             });
                             if (($('.next-content .content').prop('scrollHeight')) <= $('.next-content .content').height() + 60) {
                                 $('.next-content .content-control').hide();
                                 //                             $('.next-content .fade-overlay').hide();
                             }

                             $('.regular-content').each(function () {
                                 if (($(this).find('.content').prop('scrollHeight')) <= $(this).find('.content').height() + 60) {
                                     $(this).find('.content-control').hide();

                                     //                                 $(this).find('.fade-overlay').hide();
                                 }
                             });


                         }
                         //3 column
                     }).register("screen and (min-width: 918px) and (max-width : 1223px)", {
                         match: function () {
                             $('body').addClass('desktop');
                             $('body').removeClass('mobile');
                             fredagsrock.enableHover.Init();
                             $('#wrapper').css('padding-bottom', 0);

                             $(".eventBox.active").each(function (index, elem) {
                                 fredagsrock.eventTextToogle.toogle($(this));
                             });

                             $(".regular-content.active").each(function (index, elem) {
                                 fredagsrock.toogleArticle.Init($(this));
                             });
                             if (($('.next-content .content').prop('scrollHeight')) <= $('.next-content .content').height() + 60) {
                                 $('.next-content .content-control').hide();
                                 //                             $('.next-content .fade-overlay').hide();
                             }

                             $('.regular-content').each(function () {
                                 if (($(this).find('.content').prop('scrollHeight')) <= $(this).find('.content').height() + 60) {
                                     $(this).find('.content-control').hide();

                                     //                                 $(this).find('.fade-overlay').hide();
                                 }
                             });


                         }

                         //4 column
                     }).register("screen and (min-width: 1224px)", {
                         match: function () {
                             $('body').removeClass('desktop');
                             $('body').addClass('desktop');
                             $('body').removeClass('mobile');
                             fredagsrock.enableHover.Init();
                             $('#wrapper').css('padding-bottom', 0);

                             $(".eventBox.active").each(function (index, elem) {
                                 fredagsrock.eventTextToogle.toogle($(this));
                             });

                             $(".regular-content.active").each(function (index, elem) {
                                 fredagsrock.toogleArticle.Init($(this));
                             });
                             if (($('.next-content .content').prop('scrollHeight')) <= $('.next-content .content').height() + 60) {
                                 $('.next-content .content-control').hide();
                                 //                             $('.next-content .fade-overlay').hide();
                             }


                             $('.regular-content').each(function () {
                                 if (($(this).find('.content').prop('scrollHeight')) <= $(this).find('.content').height() + 60) {
                                     $(this).find('.content-control').hide();

                                     //                                 $(this).find('.fade-overlay').hide();
                                 }
                             });
                         }
                     }).listen()

                 }
             }
    ]);

    if ($('.event-holder').length > 0) {
        if (selectedEvent.length > 0) {
            try {
                var current = $(selectedEvent).position().top;
                var top = $(activeEvent).position().top;
                $("html, body").animate({ scrollTop: current });
                $(selectedEvent).addClass('dim-active');
                $('#dim').fadeIn(300);
            } catch (e) { }
        }
    }


});